(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@momentum-ui/web-components"));
	else if(typeof define === 'function' && define.amd)
		define(["@momentum-ui/web-components"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@momentum-ui/web-components")) : factory(root["@momentum-ui/web-components"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__momentum_ui_web_components__) {
return 