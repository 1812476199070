export const printVariable = (name: string, value: any) => {
  return [name, value, typeof value];
};

export const isJsonString = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const debugLogMessage = (infoMessage: string, ...args: any[]) => {
  console.log(`[JDS] ${infoMessage}`, ...args);
};

export const errorConsoleLog = (infoMessage: string, ...args: any[]) => {
  console.error(`[JDS] ${infoMessage}`, ...args);
};

export const isAllUpperCase = (str: string) => {
  return str === str.toUpperCase();
};
