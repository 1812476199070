import { I18N } from "@uuip/unified-ui-platform-sdk";
import bg from "./locales/bg.json";
import ca from "./locales/ca.json";
import cs from "./locales/cs.json";
import da from "./locales/da.json";
import de from "./locales/de.json";
import enGB from "./locales/en-GB.json";
import en from "./locales/en.json";
import es from "./locales/es.json";
import fi from "./locales/fi.json";
import fr from "./locales/fr.json";
import hr from "./locales/hr.json";
import hu from "./locales/hu.json";
import it from "./locales/it.json";
import ja from "./locales/ja.json";
import ko from "./locales/ko.json";
import nb from "./locales/nb.json";
import nl from "./locales/nl.json";
import pl from "./locales/pl.json";
import ptBR from "./locales/pt-BR.json";
import ptPT from "./locales/pt-PT.json";
import ro from "./locales/ro.json";
import ru from "./locales/ru.json";
import sk from "./locales/sk.json";
import sl from "./locales/sl.json";
import sr from "./locales/sr.json";
import sv from "./locales/sv.json";
import tr from "./locales/tr.json";
import uk from "./locales/uk.json";
import zhCN from "./locales/zh-CN.json";
import zhTW from "./locales/zh-TW.json";

//'cimode' the output text will be the key. (for testing)
// const lng = process.env.NODE_ENV !== "test" ? undefined : "cimode";

export const I18N_DEFAULT_INIT_OPTIONS: I18N.InitOptions = {
  fallbackLng: "en",
  debug: true,
  resources: {
    bg: {
      translation: bg,
    },
    ca: {
      translation: ca,
    },
    cs: {
      translation: cs,
    },
    da: {
      translation: da,
    },
    de: {
      translation: de,
    },
    "en-GB": {
      translation: enGB,
    },
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
    fi: {
      translation: fi,
    },
    fr: {
      translation: fr,
    },
    hr: {
      translation: hr,
    },
    hu: {
      translation: hu,
    },
    it: {
      translation: it,
    },
    ja: {
      translation: ja,
    },
    ko: {
      translation: ko,
    },
    nb: {
      translation: nb,
    },
    nl: {
      translation: nl,
    },
    pl: {
      translation: pl,
    },
    "pt-BR": {
      translation: ptBR,
    },
    "pt-PT": {
      translation: ptPT,
    },
    ro: {
      translation: ro,
    },
    ru: {
      translation: ru,
    },
    sk: {
      translation: sk,
    },
    sl: {
      translation: sl,
    },
    sr: {
      translation: sr,
    },
    sv: {
      translation: sv,
    },
    tr: {
      translation: tr,
    },
    uk: {
      translation: uk,
    },
    "zh-CN": {
      translation: zhCN,
    },
    "zh-TW": {
      translation: zhTW,
    },
  },
  detection: {
    order: ["navigator", "cookie", "queryString", "localStorage", "sessionStorage", "htmlTag", "path", "subdomain"],
    lookupQuerystring: "lng",
    lookupCookie: "lng",
    lookupLocalStorage: "lng",
    lookupSessionStorage: "lng",
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
  },
};
