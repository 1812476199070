export const mockedOrgResponse = {
  meta: {
    organizationId: "af25eb3b-7f16-4c4a-88fc-fe5765d130da",
  },
  data: {
    organizationId: "af25eb3b-7f16-4c4a-88fc-fe5765d130da",
    name: "Mocked Org Name",
    isActive: true,
    workspaces: [
      {
        id: "64dcf983534a9b2fd561391a",
        name: "mock workspace one",
        description: "Mocked Description",
        wxccSubscriptionIds: [],
      },
      {
        id: "65f1ef64bbcd682d39c9b5ca",
        name: "mock workspace two",
        description: "Mocked Description",
        wxccSubscriptionIds: [
          "282ce387-bff4-4d02-9dba-782be403ea6c",
          "391f95bb-09ef-43b4-9fb8-917ec7b09076",
          "0ea71afe-34b0-439b-a58e-c37dc5dbba5c",
          "393cbc31-a68d-49da-b0c3-bc798e90a4f4",
        ],
      },
      {
        id: "661ee935c3ebb51e8b43eeca",
        name: "mock workspace three",
        description: "Mocked Description",
        wxccSubscriptionIds: null,
      },
      {
        id: "661ee93fc3ebb51e8b43eeda",
        name: "mock workspace four",
        description: "Mocked Description",
        wxccSubscriptionIds: null,
      },
    ],
    enabledFeatureIds: [],
    settings: {
      general: {
        dataRetentionDays: 364,
      },
      webex: {
        env: null,
      },
    },
    createdAt: "2023-08-16T16:29:55.204Z",
    createdBy: "5f79089a-f397-40f1-9471-cb78874e066a",
    updatedAt: "2024-05-15T18:04:39.167Z",
    updatedBy: "5f79089a-f397-40f1-9471-cb78874e066a",
  },
};

export const mockedProfileTemplates = {
  meta: {
    organizationId: "8d7716a5-4c2e-4d6d-808e-fad10a1b6619",
  },
  data: [
    {
      id: "653bf8cfbdbcb1450caaa396",
      name: "journey-default-template",
      version: "1.0",
      workspaceId: "66a17c596830d075b53c69e0",
      organizationId: "8d7716a5-4c2e-4d6d-808e-fad10a1b6619",
      attributes: [
        {
          displayName: "Contacts within last 10 days",
          version: "0.1",
          event: "task:new",
          metaDataType: "STRING",
          metaData: "origin",
          limit: 10000,
          lookBackDurationType: "DAYS",
          lookBackPeriod: 10,
          aggregationMode: "COUNT",
          verbose: true,
          widgetAttributes: {
            type: "table",
          },
          rules: null,
        },
        {
          displayName: "Contacts within 24 hours",
          version: "0.1",
          event: "task:new",
          metaDataType: "STRING",
          metaData: "origin",
          limit: 1000,
          lookBackDurationType: "HOURS",
          lookBackPeriod: 24,
          aggregationMode: "COUNT",
          verbose: true,
          widgetAttributes: {
            type: "table",
          },
          rules: null,
        },
      ],
      createdAt: "2023-10-27T17:52:15.688Z",
      createdBy: "c96096bb-da4a-4771-a76f-d413840eef14",
      updatedAt: "2023-10-27T17:52:15.688Z",
      updatedBy: "c96096bb-da4a-4771-a76f-d413840eef14",
    },
  ],
};

export const mockedIdentityData = {
  meta: {
    organizationId: "8d7716a5-4c2e-4d6d-808e-fad10a1b6619",
  },
  data: [
    {
      id: "65455ac4023ffe7b3bdc3b71",
      firstName: "Elena",
      lastName: "Giere",
      phone: [],
      email: ["chandler@test.com"],
      temporaryId: [],
      customerId: [],
      aliases: ["chandler@test.com"],
      organizationId: "8d7716a5-4c2e-4d6d-808e-fad10a1b6619",
      workspaceId: "653bf8cfbdbcb1450caaa394",
      createdAt: "2023-11-03T20:40:36.943Z",
      createdBy: "journey-stream-profiles",
      updatedAt: "2024-02-09T04:05:19.547Z",
      updatedBy: "08e5f705-0926-4677-aedd-66dd30659aec",
    },
  ],
};

export const mockedProfileView = {
  meta: {
    personId: "65455ac4023ffe7b3bdc3b71",
    templateId: "653bf8cfbdbcb1450caaa396",
    organizationId: "8d7716a5-4c2e-4d6d-808e-fad10a1b6619",
    workspaceId: "653bf8cfbdbcb1450caaa394",
  },
  data: [
    {
      workspaceId: "653bf8cfbdbcb1450caaa394",
      organizationId: "8d7716a5-4c2e-4d6d-808e-fad10a1b6619",
      personId: "65455ac4023ffe7b3bdc3b71",
      templateId: "653bf8cfbdbcb1450caaa396",
      searchFilter: null,
      attributes: [
        {
          result: "6",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 10,
            displayName: "Contacts within last 10 days",
            widgetAttributes: {
              type: "table",
            },
            limit: 10000,
            metaDataType: "string",
            lookBackDurationType: "days",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-07T17:24:30.92Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707326670401,"channelType":"chat","taskId":"d5f5221d-85f1-40bf-837c-0306c2e94325","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T05:10:27.79Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707196227646,"channelType":"chat","taskId":"56eb9c88-e8d9-4124-83c1-9761ca5c02f5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T00:17:01.419Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707178621252,"channelType":"chat","taskId":"4dca7533-0698-469c-8f78-bffbcc9507a7","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-05T23:33:38.288Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707176018143,"channelType":"chat","taskId":"9da59da0-706d-4bff-8564-d643712321e5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-03T00:05:51.567Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1706918751429,"channelType":"chat","taskId":"5f66cc3b-6ec9-4407-a3fb-beeed0407226","direction":"INBOUND"}}',
          ],
          error: "",
        },
        {
          result: "1",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 24,
            displayName: "Contacts within 24 hours",
            widgetAttributes: {
              type: "table",
            },
            limit: 1000,
            metaDataType: "string",
            lookBackDurationType: "hours",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
          ],
          error: "",
        },
      ],
      systemMetdata: null,
      timestamp: "2024-02-09T04:05:19.726Z",
    },
  ],
};

export const mockedProfileViewThree = {
  meta: {
    personId: "65455ac4023ffe7b3bdc3b71",
    templateId: "653bf8cfbdbcb1450caaa396",
    organizationId: "8d7716a5-4c2e-4d6d-808e-fad10a1b6619",
    workspaceId: "653bf8cfbdbcb1450caaa394",
  },
  data: [
    {
      workspaceId: "653bf8cfbdbcb1450caaa394",
      organizationId: "8d7716a5-4c2e-4d6d-808e-fad10a1b6619",
      personId: "65455ac4023ffe7b3bdc3b71",
      templateId: "653bf8cfbdbcb1450caaa396",
      searchFilter: null,
      attributes: [
        {
          result: "6",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 10,
            displayName: "Contacts within last 10 days",
            widgetAttributes: {
              type: "table",
            },
            limit: 10000,
            metaDataType: "string",
            lookBackDurationType: "days",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-07T17:24:30.92Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707326670401,"channelType":"chat","taskId":"d5f5221d-85f1-40bf-837c-0306c2e94325","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T05:10:27.79Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707196227646,"channelType":"chat","taskId":"56eb9c88-e8d9-4124-83c1-9761ca5c02f5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T00:17:01.419Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707178621252,"channelType":"chat","taskId":"4dca7533-0698-469c-8f78-bffbcc9507a7","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-05T23:33:38.288Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707176018143,"channelType":"chat","taskId":"9da59da0-706d-4bff-8564-d643712321e5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-03T00:05:51.567Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1706918751429,"channelType":"chat","taskId":"5f66cc3b-6ec9-4407-a3fb-beeed0407226","direction":"INBOUND"}}',
          ],
          error: "",
        },
        {
          result: "4",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 10,
            displayName: "Contacts within last 7 days",
            widgetAttributes: {
              type: "table",
            },
            limit: 10000,
            metaDataType: "string",
            lookBackDurationType: "days",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-07T17:24:30.92Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707326670401,"channelType":"chat","taskId":"d5f5221d-85f1-40bf-837c-0306c2e94325","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T05:10:27.79Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707196227646,"channelType":"chat","taskId":"56eb9c88-e8d9-4124-83c1-9761ca5c02f5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T00:17:01.419Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707178621252,"channelType":"chat","taskId":"4dca7533-0698-469c-8f78-bffbcc9507a7","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-05T23:33:38.288Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707176018143,"channelType":"chat","taskId":"9da59da0-706d-4bff-8564-d643712321e5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-03T00:05:51.567Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1706918751429,"channelType":"chat","taskId":"5f66cc3b-6ec9-4407-a3fb-beeed0407226","direction":"INBOUND"}}',
          ],
          error: "",
        },
        {
          result: "1",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 24,
            displayName: "Contacts within 24 hours",
            widgetAttributes: {
              type: "table",
            },
            limit: 1000,
            metaDataType: "string",
            lookBackDurationType: "hours",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
          ],
          error: "",
        },
      ],
      systemMetdata: null,
      timestamp: "2024-02-09T04:05:19.726Z",
    },
  ],
};

export const mockedProfileViewLong = {
  meta: {
    personId: "65455ac4023ffe7b3bdc3b71",
    templateId: "653bf8cfbdbcb1450caaa396",
    organizationId: "8d7716a5-4c2e-4d6d-808e-fad10a1b6619",
    workspaceId: "653bf8cfbdbcb1450caaa394",
  },
  data: [
    {
      workspaceId: "653bf8cfbdbcb1450caaa394",
      organizationId: "8d7716a5-4c2e-4d6d-808e-fad10a1b6619",
      personId: "65455ac4023ffe7b3bdc3b71",
      templateId: "653bf8cfbdbcb1450caaa396",
      searchFilter: null,
      attributes: [
        {
          result: "6",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 10,
            displayName: "Contacts within last 10 days",
            widgetAttributes: {
              type: "table",
            },
            limit: 10000,
            metaDataType: "string",
            lookBackDurationType: "days",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-07T17:24:30.92Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707326670401,"channelType":"chat","taskId":"d5f5221d-85f1-40bf-837c-0306c2e94325","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T05:10:27.79Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707196227646,"channelType":"chat","taskId":"56eb9c88-e8d9-4124-83c1-9761ca5c02f5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T00:17:01.419Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707178621252,"channelType":"chat","taskId":"4dca7533-0698-469c-8f78-bffbcc9507a7","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-05T23:33:38.288Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707176018143,"channelType":"chat","taskId":"9da59da0-706d-4bff-8564-d643712321e5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-03T00:05:51.567Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1706918751429,"channelType":"chat","taskId":"5f66cc3b-6ec9-4407-a3fb-beeed0407226","direction":"INBOUND"}}',
          ],
          error: "",
        },
        {
          result: "18",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 10,
            displayName: "Contacts within last 20 days",
            widgetAttributes: {
              type: "table",
            },
            limit: 10000,
            metaDataType: "string",
            lookBackDurationType: "days",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-07T17:24:30.92Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707326670401,"channelType":"chat","taskId":"d5f5221d-85f1-40bf-837c-0306c2e94325","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T05:10:27.79Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707196227646,"channelType":"chat","taskId":"56eb9c88-e8d9-4124-83c1-9761ca5c02f5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T00:17:01.419Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707178621252,"channelType":"chat","taskId":"4dca7533-0698-469c-8f78-bffbcc9507a7","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-05T23:33:38.288Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707176018143,"channelType":"chat","taskId":"9da59da0-706d-4bff-8564-d643712321e5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-03T00:05:51.567Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1706918751429,"channelType":"chat","taskId":"5f66cc3b-6ec9-4407-a3fb-beeed0407226","direction":"INBOUND"}}',
          ],
          error: "",
        },
        {
          result: "50",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 10,
            displayName: "Contacts within last 2 months",
            widgetAttributes: {
              type: "table",
            },
            limit: 10000,
            metaDataType: "string",
            lookBackDurationType: "days",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-07T17:24:30.92Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707326670401,"channelType":"chat","taskId":"d5f5221d-85f1-40bf-837c-0306c2e94325","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T05:10:27.79Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707196227646,"channelType":"chat","taskId":"56eb9c88-e8d9-4124-83c1-9761ca5c02f5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T00:17:01.419Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707178621252,"channelType":"chat","taskId":"4dca7533-0698-469c-8f78-bffbcc9507a7","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-05T23:33:38.288Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707176018143,"channelType":"chat","taskId":"9da59da0-706d-4bff-8564-d643712321e5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-03T00:05:51.567Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1706918751429,"channelType":"chat","taskId":"5f66cc3b-6ec9-4407-a3fb-beeed0407226","direction":"INBOUND"}}',
          ],
          error: "",
        },
        {
          result: "1203",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 10,
            displayName: "Contacts within last 1 year",
            widgetAttributes: {
              type: "table",
            },
            limit: 10000,
            metaDataType: "string",
            lookBackDurationType: "days",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-07T17:24:30.92Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707326670401,"channelType":"chat","taskId":"d5f5221d-85f1-40bf-837c-0306c2e94325","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T05:10:27.79Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707196227646,"channelType":"chat","taskId":"56eb9c88-e8d9-4124-83c1-9761ca5c02f5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T00:17:01.419Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707178621252,"channelType":"chat","taskId":"4dca7533-0698-469c-8f78-bffbcc9507a7","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-05T23:33:38.288Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707176018143,"channelType":"chat","taskId":"9da59da0-706d-4bff-8564-d643712321e5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-03T00:05:51.567Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1706918751429,"channelType":"chat","taskId":"5f66cc3b-6ec9-4407-a3fb-beeed0407226","direction":"INBOUND"}}',
          ],
          error: "",
        },
        {
          result: "15010",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 10,
            displayName: "Contacts within last 5 years",
            widgetAttributes: {
              type: "table",
            },
            limit: 10000,
            metaDataType: "string",
            lookBackDurationType: "days",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-07T17:24:30.92Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707326670401,"channelType":"chat","taskId":"d5f5221d-85f1-40bf-837c-0306c2e94325","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T05:10:27.79Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707196227646,"channelType":"chat","taskId":"56eb9c88-e8d9-4124-83c1-9761ca5c02f5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T00:17:01.419Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707178621252,"channelType":"chat","taskId":"4dca7533-0698-469c-8f78-bffbcc9507a7","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-05T23:33:38.288Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707176018143,"channelType":"chat","taskId":"9da59da0-706d-4bff-8564-d643712321e5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-03T00:05:51.567Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1706918751429,"channelType":"chat","taskId":"5f66cc3b-6ec9-4407-a3fb-beeed0407226","direction":"INBOUND"}}',
          ],
          error: "",
        },
        {
          result: "100001",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 10,
            displayName: "Contacts within last 10 years",
            widgetAttributes: {
              type: "table",
            },
            limit: 10000,
            metaDataType: "string",
            lookBackDurationType: "days",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-07T17:24:30.92Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707326670401,"channelType":"chat","taskId":"d5f5221d-85f1-40bf-837c-0306c2e94325","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T05:10:27.79Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707196227646,"channelType":"chat","taskId":"56eb9c88-e8d9-4124-83c1-9761ca5c02f5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T00:17:01.419Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707178621252,"channelType":"chat","taskId":"4dca7533-0698-469c-8f78-bffbcc9507a7","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-05T23:33:38.288Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707176018143,"channelType":"chat","taskId":"9da59da0-706d-4bff-8564-d643712321e5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-03T00:05:51.567Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1706918751429,"channelType":"chat","taskId":"5f66cc3b-6ec9-4407-a3fb-beeed0407226","direction":"INBOUND"}}',
          ],
          error: "",
        },
        {
          result: "3",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 10,
            displayName: "Contacts within last 48 hours",
            widgetAttributes: {
              type: "table",
            },
            limit: 10000,
            metaDataType: "string",
            lookBackDurationType: "days",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-07T17:24:30.92Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707326670401,"channelType":"chat","taskId":"d5f5221d-85f1-40bf-837c-0306c2e94325","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T05:10:27.79Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707196227646,"channelType":"chat","taskId":"56eb9c88-e8d9-4124-83c1-9761ca5c02f5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T00:17:01.419Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707178621252,"channelType":"chat","taskId":"4dca7533-0698-469c-8f78-bffbcc9507a7","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-05T23:33:38.288Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707176018143,"channelType":"chat","taskId":"9da59da0-706d-4bff-8564-d643712321e5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-03T00:05:51.567Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1706918751429,"channelType":"chat","taskId":"5f66cc3b-6ec9-4407-a3fb-beeed0407226","direction":"INBOUND"}}',
          ],
          error: "",
        },
        {
          result: "20",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 10,
            displayName: "Contacts within last week",
            widgetAttributes: {
              type: "table",
            },
            limit: 10000,
            metaDataType: "string",
            lookBackDurationType: "days",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-07T17:24:30.92Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707326670401,"channelType":"chat","taskId":"d5f5221d-85f1-40bf-837c-0306c2e94325","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T05:10:27.79Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707196227646,"channelType":"chat","taskId":"56eb9c88-e8d9-4124-83c1-9761ca5c02f5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-06T00:17:01.419Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707178621252,"channelType":"chat","taskId":"4dca7533-0698-469c-8f78-bffbcc9507a7","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-05T23:33:38.288Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707176018143,"channelType":"chat","taskId":"9da59da0-706d-4bff-8564-d643712321e5","direction":"INBOUND"}}',
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-03T00:05:51.567Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1706918751429,"channelType":"chat","taskId":"5f66cc3b-6ec9-4407-a3fb-beeed0407226","direction":"INBOUND"}}',
          ],
          error: "",
        },
        {
          result: "1",
          queryTemplate: {
            metaData: "origin",
            lookBackPeriod: 24,
            displayName: "Contacts within 24 hours",
            widgetAttributes: {
              type: "table",
            },
            limit: 1000,
            metaDataType: "string",
            lookBackDurationType: "hours",
            event: "task:new",
            version: "0.1",
            aggregationMode: "Count",
            verbose: "true",
          },
          journeyEvents: [
            '{"organizationId":null,"workspaceId":null,"id":"id","specVersion":"1.0","type":"task:new","source":"/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa","time":"2024-02-09T03:53:00.093Z","identity":"chandler@test.com","identityType":"email","previousIdentity":null,"dataContentType":"application/json","data":{"origin":"chandler@test.com","workflowManager":"IMI","destination":"LiveChatEP","createdTime":1707450779874,"channelType":"chat","taskId":"04343c5a-1a5e-449a-a0d5-379d3d715d8c","direction":"INBOUND"}}',
          ],
          error: "",
        },
      ],
      systemMetdata: null,
      timestamp: "2024-02-09T04:05:19.726Z",
    },
  ],
};

export const mockedHistoricalEvents = {
  meta: {
    organizationId: "8d7716a5-4c2e-4d6d-808e-fad10a1b6619",
    workspaceId: "653bf8cfbdbcb1450caaa394",
    resultCount: 23,
    identity: "chandler@test.com",
  },
  data: [
    {
      id: "de293c44-5904-465d-9a10-d6797b451d62",
      specversion: "1.0",
      type: "Payment",
      source: "System",
      time: "2024-08-09T04:53:01.108Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        uiData: {
          subTitle: "Payment Complete",
          iconType: "Quote",
          title: "Payment notification message",
          filterTags: ["my payments", "Quotes"],
        },
        "Past Due Amount": "$544.73",
        channel: "SMS",
        category: "Personal Loan",
        status: "Payment Completed in branch",
      },
    },
    {
      id: "113284b2-86c0-4473-9983-fd5da9b90679",
      specversion: "1.0",
      type: "task:connected",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-09T03:53:02.907Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        destination: "LiveChatEP",
        createdTime: 1707450782757,
        channelType: "chat",
        taskId: "04343c5a-1a5e-449a-a0d5-379d3d715d8c",
        direction: "INBOUND",
      },
    },
    {
      id: "e06249da-265c-48d2-9db4-424177c1fc70",
      specversion: "1.0",
      type: "task:connect",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-09T03:53:00.611Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707450780478,
        channelType: "chat",
        taskId: "04343c5a-1a5e-449a-a0d5-379d3d715d8c",
        direction: "INBOUND",
      },
    },
    {
      id: "30d43279-fb99-4433-8232-d4092a613547",
      specversion: "1.0",
      type: "task:new",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-09T03:53:00.093Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707450779874,
        channelType: "chat",
        taskId: "04343c5a-1a5e-449a-a0d5-379d3d715d8c",
        direction: "INBOUND",
      },
    },
    {
      id: "c6095e39-0ad1-46cf-84a6-a3e44bc168ee",
      specversion: "1.0",
      type: "task:ended",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-07T17:25:10.322Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        reason: "Agent Left",
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        terminatingParty: "Agent",
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707326710158,
        channelType: "chat",
        taskId: "d5f5221d-85f1-40bf-837c-0306c2e94325",
        direction: "INBOUND",
      },
    },
    {
      id: "0b57d3c6-23db-4db1-967a-56c6056bef95",
      specversion: "1.0",
      type: "task:connected",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-07T17:24:34.051Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        destination: "LiveChatEP",
        createdTime: 1707326673889,
        channelType: "chat",
        taskId: "d5f5221d-85f1-40bf-837c-0306c2e94325",
        direction: "INBOUND",
      },
    },
    {
      id: "6d6493ea-65bc-45c7-aa45-84d6624f994a",
      specversion: "1.0",
      type: "task:connect",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-07T17:24:31.091Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707326670869,
        channelType: "chat",
        taskId: "d5f5221d-85f1-40bf-837c-0306c2e94325",
        direction: "INBOUND",
      },
    },
    {
      id: "8208f58d-8730-4d91-a345-3757fe8bbf18",
      specversion: "1.0",
      type: "task:new",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-07T17:24:30.92Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707326670401,
        channelType: "chat",
        taskId: "d5f5221d-85f1-40bf-837c-0306c2e94325",
        direction: "INBOUND",
      },
    },
    {
      id: "e01733b7-b232-455f-9cf4-26a28233c5be",
      specversion: "1.0",
      type: "task:connected",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-06T05:10:43.629Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        destination: "LiveChatEP",
        createdTime: 1707196243504,
        channelType: "chat",
        taskId: "56eb9c88-e8d9-4124-83c1-9761ca5c02f5",
        direction: "INBOUND",
      },
    },
    {
      id: "227fed19-4954-47e7-a085-84749c3f5847",
      specversion: "1.0",
      type: "task:connect",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-06T05:10:28.229Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707196228074,
        channelType: "chat",
        taskId: "56eb9c88-e8d9-4124-83c1-9761ca5c02f5",
        direction: "INBOUND",
      },
    },
    {
      id: "f648df33-7d8f-4f80-8822-7b83d01f3816",
      specversion: "1.0",
      type: "task:new",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-06T05:10:27.79Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707196227646,
        channelType: "chat",
        taskId: "56eb9c88-e8d9-4124-83c1-9761ca5c02f5",
        direction: "INBOUND",
      },
    },
    {
      id: "864c4a76-a0c0-4ef8-a8b1-4bc7d695f5db",
      specversion: "1.0",
      type: "task:ended",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-06T01:06:53.127Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        reason: "Agent Left",
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        terminatingParty: "Agent",
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707181612965,
        channelType: "chat",
        taskId: "4dca7533-0698-469c-8f78-bffbcc9507a7",
        direction: "INBOUND",
      },
    },
    {
      id: "01dad5e9-8e46-45a4-b681-6597fa99d461",
      specversion: "1.0",
      type: "task:connected",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-06T00:17:16.389Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        destination: "LiveChatEP",
        createdTime: 1707178636228,
        channelType: "chat",
        taskId: "4dca7533-0698-469c-8f78-bffbcc9507a7",
        direction: "INBOUND",
      },
    },
    {
      id: "08621a99-f3a1-40a2-9df7-e122a456e55b",
      specversion: "1.0",
      type: "task:connect",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-06T00:17:01.759Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707178621633,
        channelType: "chat",
        taskId: "4dca7533-0698-469c-8f78-bffbcc9507a7",
        direction: "INBOUND",
      },
    },
    {
      id: "8d7d0ece-504f-421d-a772-6a10e236174a",
      specversion: "1.0",
      type: "task:new",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-06T00:17:01.419Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707178621252,
        channelType: "chat",
        taskId: "4dca7533-0698-469c-8f78-bffbcc9507a7",
        direction: "INBOUND",
      },
    },
    {
      id: "87265e0e-9992-4535-8b0b-c61278a504b9",
      specversion: "1.0",
      type: "task:ended",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-05T23:56:34.878Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        reason: "Agent Left",
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        terminatingParty: "Agent",
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707177394712,
        channelType: "chat",
        taskId: "9da59da0-706d-4bff-8564-d643712321e5",
        direction: "INBOUND",
      },
    },
    {
      id: "410731a5-3fb7-488d-ad6b-728c53e5aefb",
      specversion: "1.0",
      type: "task:connected",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-05T23:33:41.966Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        destination: "LiveChatEP",
        createdTime: 1707176021843,
        channelType: "chat",
        taskId: "9da59da0-706d-4bff-8564-d643712321e5",
        direction: "INBOUND",
      },
    },
    {
      id: "7f042a82-834e-4cbc-9fa0-a86b5cf69e95",
      specversion: "1.0",
      type: "task:connect",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-05T23:33:38.644Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707176018510,
        channelType: "chat",
        taskId: "9da59da0-706d-4bff-8564-d643712321e5",
        direction: "INBOUND",
      },
    },
    {
      id: "f4db6230-823e-44a9-b963-a914e16b80a2",
      specversion: "1.0",
      type: "task:new",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-05T23:33:38.288Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1707176018143,
        channelType: "chat",
        taskId: "9da59da0-706d-4bff-8564-d643712321e5",
        direction: "INBOUND",
      },
    },
    {
      id: "87cf00be-44e3-4c81-ac6a-2e00eb97ad05",
      specversion: "1.0",
      type: "task:connected",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-03T00:05:56.689Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        destination: "LiveChatEP",
        createdTime: 1706918756565,
        channelType: "chat",
        taskId: "5f66cc3b-6ec9-4407-a3fb-beeed0407226",
        direction: "INBOUND",
      },
    },
    {
      id: "c500973d-1488-412f-888f-8ed806afc1d9",
      specversion: "1.0",
      type: "task:connect",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-03T00:05:54.173Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1706918754050,
        channelType: "chat",
        taskId: "5f66cc3b-6ec9-4407-a3fb-beeed0407226",
        direction: "INBOUND",
      },
    },
    {
      id: "f949b4a1-ec07-4ba4-8e38-25bdbddabbca",
      specversion: "1.0",
      type: "task:parked",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-03T00:05:51.988Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        queueId: "a8c4d770-3274-421b-aa10-9435beb77e70",
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1706918751868,
        channelType: "chat",
        taskId: "5f66cc3b-6ec9-4407-a3fb-beeed0407226",
        direction: "INBOUND",
      },
    },
    {
      id: "39327c1b-68d1-415e-a4e9-c1e65b3da330",
      specversion: "1.0",
      type: "task:new",
      source: "/com/cisco/wxcc/9f690291-a597-406c-b61b-5af104d52bfa",
      time: "2024-02-03T00:05:51.567Z",
      identity: "chandler@test.com",
      identitytype: "email",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        origin: "chandler@test.com",
        workflowManager: "IMI",
        destination: "LiveChatEP",
        createdTime: 1706918751429,
        channelType: "chat",
        taskId: "5f66cc3b-6ec9-4407-a3fb-beeed0407226",
        direction: "INBOUND",
      },
    },
  ],
};

export const mockedHistoricalEventsTimezoneTest = {
  meta: {
    organizationId: "8d7716a5-4c2e-4d6d-808e-fad10a1b6619",
    workspaceId: "653bf8cfbdbcb1450caaa394",
    resultCount: 3,
    identity: "+8001234567",
  },
  data: [
    {
      id: "4c3f0028-9505-4747-b035-8acc86aa815b",
      specversion: "1.0",
      type: "agent:state_change",
      source: "/com/cisco/wxcc/d7497f3e-d4b9-4930-9097-6a6607235dfd",
      time: "2024-07-31T19:12:12.625Z",
      identity: "+8001234567",
      identitytype: "phone",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        wrapUpAuxCodeId: "8d1fa125-add5-4188-8f5b-46fd20c4e1e6",
        queueId: "79505c19-1de2-400e-91bd-0642ded16c0a",
        agentId: "5939040e-ba5d-41f4-bfa0-1bba6268f603",
        queueName: "Queue-Cars-Voice",
        identity: "+8001234567",
        wrapUpAuxCodeName: "Billing Enquiry",
        origin: "+8001234567",
        createdTime: 1722453132471,
        agentName: "Chandler Bing",
        channelType: "telephony",
        currentState: "wrapup-done",
        taskId: "1a416933-7f0c-47a2-a421-654ca6c8813b",
      },
    },
    {
      id: "cfdd1550-b72d-4897-a56b-bba37e84b308",
      specversion: "1.0",
      type: "task:new",
      source: "Agent",
      time: "2024-07-31T16:15:40.57Z",
      identity: "+8001234567",
      identitytype: "phone",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        uiData: {
          subTitle: "Test 5 ",
          iconType: "SMS",
          title: "Agent SMS",
        },
        subject: "Test 5 ",
        channel: "SMS - Message ",
        customerId: "+8001234567",
        channelType: "SMS",
        custName: "Chandler Bing",
        url: "NA",
        direction: "Outbound",
      },
      renderingData: {
        title: "Agent SMS",
        subTitle: "Test 5 ",
        iconType: "SMS",
        eventSource: "Agent",
        isActive: false,
      },
    },
    {
      id: "fb1a886f-3de3-49ef-af7d-fff2249b9cb4",
      specversion: "1.0",
      type: "agent:state_change",
      source: "/com/cisco/wxcc/d7497f3e-d4b9-4930-9097-6a6607235dfd",
      time: "2024-07-31T13:57:32.839Z",
      identity: "+8001234567",
      identitytype: "phone",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        reason: "System disconnected the contact",
        terminatingParty: "System",
        origin: "+8001234567",
        destination: "+61251045855",
        createdTime: 1722434252700,
        channelType: "telephony",
        taskId: "d151110d-fce7-4daf-8430-b99f1f8d5a61",
        direction: "INBOUND",
        wrapUpAuxCodeId: "8d1fa125-add5-4188-8f5b-46fd20c4e1e6",
        queueId: "79505c19-1de2-400e-91bd-0642ded16c0a",
        agentId: "5939040e-ba5d-41f4-bfa0-1bba6268f603",
        queueName: "Queue-Cars-Voice",
        identity: "+8001234567",
        wrapUpAuxCodeName: "Billing Enquiry",
        agentName: "Chandler Bing",
        currentState: "wrapup-done",
      },
    },
    {
      id: "311c37c8-0c65-4d5d-a832-4567c7a20012",
      specversion: "1.0",
      type: "task:new",
      source: "IVA",
      time: "2024-07-30T11:15:33.821Z",
      identity: "+8001234567",
      identitytype: "phone",
      previousidentity: null,
      datacontenttype: "application/json",
      data: {
        subject: "Change Middle name to Chandler Muriel Bing.",
        origin: "Change Details",
        channel: "Whatsapp",
        customerId: "7777",
        channelType: "WhatsApp",
        custName: "Chandler Bing",
        url: "n/a",
      },
      renderingData: {
        title: "+8001234567",
        subTitle:
          "subject: Change Middle name to Chandler Muriel Bing., origin: Change Details, channel: Whatsapp, customerId: 7777, channelType: WhatsApp, custName: Chandler Bing, url: n/a",
        iconType: "WhatsApp",
        eventSource: "IVA",
        isActive: false,
      },
    },
  ],
};
